<template>
  <div class="category-menu-section">
    <a-result status="500" title="500" sub-title="Sorry, the server is wrong, try again latter."></a-result>
  </div>
</template>

<script>
export default {
  name: "Error-500"
}
</script>

<style scoped>

</style>
